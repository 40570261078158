/* table scrollable */
*::-webkit-scrollbar {
  width: 3px;
  background: transparent;
}
/* *::-webkit-scrollbar-track {
} */
*::-webkit-scrollbar-thumb {
  background-color: #110d0e;
}
td {
  overflow: hidden;
  z-index: 5;
}

.p-row-expanded > td {
  padding: 0 !important;
}

@media (min-width: 1550) {
  .p-formgroup.flex-inline > p {
    position: absolute;
    left: 1rem;
    z-index: 5;
  }
}

/* show password  */
i.pi.pi-eye,
i.pi.pi-eye-slash {
  color: var(--primary-color) !important;
}

/* submit btn */
.submit-btn {
  margin-top: 2rem !important;
}
.submit-btn,
.submit-btn:hover,
.submit-btn:active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-weight: 600 !important;
}

/* all field component */
.p-field {
  margin-bottom: 2rem;
}
.p-field > input:focus,
#password > input:focus,
.p-inputtextarea:focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
}
.p-field > input:hover,
#password > input:hover,
.p-inputtextarea:hover {
  border-color: var(--primary-color) !important;
}
.p-checkbox-box.p-highlight {
  background-color: var(--primary-color) !important;
  border-color: var(--shadow-color) !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--shadow-color) !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox.p-focues {
  border-color: var(--shadow-color) !important;
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
}
.p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  border: var(--primary-color) !important;
}
.p-multiselect:hover {
  border-color: var(--primary-color) !important;
}
.p-multiselect .p-multiselect-clear-icon {
  color: var(--primary-color) !important;
}
.p-multiselect .p-multiselect-label {
  color: #495057;
}
.p-multiselect-token {
  background-color: var(--primary-color) !important;
  color: #fff;
}
.p-multiselect-token:hover {
  border-color: var(--primary-color) !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  color: #fff !important;
}
.p-multiselect .p-multiselect-token-label {
  color: #fff;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
}

.ql-editor.ql-blank {
  min-height: 159px !important;
}
.ql-editor {
  min-height: 159px !important;
}

.custom-calender.p-calendar {
  max-width: 140px;
}
