.golden-btn,
.golden-btn:hover,
.golden-btn:active {
  position: fixed !important;
  right: 4rem;
  top: 4rem;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-weight: 600 !important;
  transition: transform 80ms ease-in-out;
}

.filter-btn.golden-btn,
.golden-outlined-btn {
  position: static !important;
}
.filter-btn.golden-btn:focus,
.golden-outlined-btn:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  border-color: var(--primary-color);
}

.golden-btn:hover,
.golden-outlined-btn:hover {
  transform: translateY(-1.5px);
}
.golden-btn:active,
.golden-outlined-btn:active {
  transform: translateY(0);
}
.golden-outlined-btn:active {
  background-color: hsla(37, 100%, 50%, 0.16) !important;
}

.golden-outlined-btn {
  color: var(--primary-color) !important;
}

.flex-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-inline > div,
.flex-inline > button {
  margin-left: 1rem;
}

span.p-multiselect-trigger-icon.p-c.pi.pi-chevron-down,
span.p-multiselect-close-icon.pi.pi-times,
span.p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down,
i.p-dropdown-clear-icon.pi.pi-times {
  color: var(--primary-color) !important;
}
.p-multiselect.p-component.p-inputwrapper.multiselect-custom:hover,
input.p-inputtext.p-component.p-multiselect-filter:hover,
.p-dropdown.p-component.p-inputwrapper.p-dropdown-clearable:hover,
input.p-dropdown-filter.p-inputtext.p-component:hover {
  border-color: var(--primary-color);
}
.p-multiselect.p-component.p-inputwrapper.multiselect-custom,
.p-dropdown.p-component.p-inputwrapper.p-dropdown-clearable {
  min-width: 11.5rem;
}

.p-multiselect.p-component.p-inputwrapper.multiselect-custom.p-focus,
input.p-inputtext.p-component.p-multiselect-filter:focus,
.p-dropdown.p-component.p-inputwrapper.p-dropdown-clearable.p-focus,
input.p-dropdown-filter.p-inputtext.p-component:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  border-color: var(--primary-color);
}

button.p-button.p-button-icon-only {
  background: var(--primary-color);
  border: 1px solid var(--shadow-color);
}
button.p-button.p-button-icon-only:hover {
  background: #d1850a;
  border: 1px solid var(--shadow-color);
}

input.p-inputtext.p-component:hover,
button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
  border: 1px solid var(--shadow-color);
}

input.p-inputtext.p-component:focus,
button.p-button.p-button-icon-only:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  border-color: var(--primary-color);
}

.actions {
  display: flex;
  justify-content: space-evenly;
}

.p-autocomplete-token {
  background: var(--primary-color) !important;
  color: #fff !important;
}
.p-autocomplete-multiple-container:hover {
  border: 1px solid var(--shadow-color) !important;
}
.p-autocomplete-multiple-container:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  border: var(--primary-color) !important;
}

.p-inputwrapper-focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  border: var(--primary-color) !important;
}

.p-autocomplete-multiple-container.p-focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  border: var(--primary-color) !important;
}

.active {
  background-color: var(--primary-color);
}
