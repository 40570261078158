:root {
  --primary-color: #d79225 !important;
  --shadow-color: #e3c390;
  --secondry-color: #110d0e;
  --bc-color: #fff;
  --secondry-bc-color: #000;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('./assets/images/background.png');
  background-color: #efefef;
  /* background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  position: relative;
}
html {
  font-family: 'Roboto', sans-serif;
}

span.p-dialog-header-close-icon.pi.pi-times {
  color: var(--primary-color);
}

.custom-other {
  transition: all ease-in-out 350ms;
  width: 0;
  transform-origin: center;
  transform: translate(0, -1rem);
  opacity: 0;
  display: none;
}
/* table styles */

.p-dialog-right.p-dialog {
  margin: 0;
  max-height: auto;
}

iframe {
  width: 100%;
  height: calc(100vh - 119px);
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 75vh;
}

.p-datepicker-other-month {
  opacity: 0;
}

@media (max-width: 1020px) {
  html {
    /* display: none; */
    /* transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0; */
  }
}
